import React, { Suspense } from 'react';
import { conProyectos } from '../../conexiones/varConexiones.js';
import './seccion.css';
import {fetchData} from '../../fetch/fetchData.js';
import Miniatura from '../miniatura/Miniatura';

const apiData = fetchData(conProyectos);


const Seccion = (props, ref) => {
    const data = apiData.read();
    return (
        <div className="main">
            <div id={props.categoria} style={{display: 'flex',flexDirection: 'column',gap: '16px'}}>
                <div className='seccion_title' id="title">
                    <h2>{props.categoria}</h2>
                </div>
                <div id={props.catId} className="demo">
                    <Suspense key='s2'  fallback={<div>Cargando...</div>}>
                        {data?.listaproyectos.map(//mapeando la lista de proyectos
                            (proy)=>{
                                if(proy.category === props.catId){
                                const img = proy.images !== null ? proy.images.split(','):null
                                return proy.images !== null ?  <Miniatura 
                                    key={proy.id} 
                                    proy={proy.id}
                                    categoria={props.categoria} 
                                    titulo={proy.name} 
                                    cliente={proy.cliente}
                                    año={proy.año} 
                                    imagen={img[0]}
                                    action={props.action}>
                                </Miniatura>:
                                <Miniatura 
                                    key={proy.id} 
                                    proy={proy.id} 
                                    categoria={props.categoria} 
                                    titulo={proy.name} 
                                    cliente={proy.cliente}
                                    año={proy.año} 
                                    imagen={'#'}
                                    action={props.action}>
                                </Miniatura>
                                } return null;
                            }
                        )}
                    </Suspense>
                </div>
            </div>
        </div>
    )
}

export default Seccion;