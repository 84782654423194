import React, { useEffect, useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import './formulario.css';

const Mensaje = (props) =>{
    useEffect(()=>{
        setTimeout(()=>{
            props.action({type:'none',mensaje:''})
        },6000)
    })
    return (
        <div className={props.data.type === 'error' ? "formularioContacto-mensaje _error":props.data.type === 'ok' ? "formularioContacto-mensaje _ok":null}>{props.data.mensaje}</div>
    )
}

const Formulario = () => {
    const formR = useRef();
    const [mensaje,setMensaje] = useState({type:'none',mensaje:''});
    const {register, formState:{errors}, handleSubmit} = useForm();
    const msg = 'Debes llenar este campo.'

    const enviaForm = (data) =>{
        const formulario = document.getElementById('contactForm')
        const form = new FormData(formulario)
        /* console.log('Cat: '+form.get('nuevaCatH')+' - '+form.get('categoriaProyecto')) */
        fetch('https://davmund.cl/mailer/mail.php',{
            method:'POST',
            body: form
        })
        .then(res => res.json())
        .then(dataN => {
            //console.log(data);
            return dataN === 'ok' ? (setMensaje({type:'ok',mensaje:'Mail enviado con exito. Pronto me comunicaré contigo.'}),formR.current.reset()):
                                    setMensaje({type:'error',mensaje:'Hubo un problema al enviar el mail. Por favor intenta nuevamente.'})
        })
    }
    return (
        <form ref={formR} id='contactForm' className="formularioContacto" style={{gridColumn: `1 / 7`}} onSubmit={handleSubmit(enviaForm)}>
            {mensaje.type !== 'none' ? <Mensaje action={setMensaje} data={mensaje} />:''}
            <label htmlFor="nombre">
                Tu nombre
                <input id="nombre" type="text" placeholder="Ej: Fernando García" className="formShadow"
                {...register('nombre',{required:true})} />
                {errors.nombre?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <label htmlFor="mail">
                Ingresa tu correo
                <input id="mail"  type="email" placeholder="Ej:tucorreo@mail.com" autoComplete='email' className="formShadow"
                {...register('mail',{required:true})} />
                {errors.mail?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <label htmlFor="asunto">
                Ingresa el asunto
                <input id="asunto" name="asunto" type="text" placeholder="Ej: cotización proyecto web" className="formShadow"
                {...register('asunto',{required:true})} />
                 {errors.asunto?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <label htmlFor="mensaje">
                Escribe tu mensaje
                <textarea id="mensaje" name="mensaje" className="formShadow"
                {...register('mensaje',{required:true})} />
                {errors.mensaje?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <button type="submit">Enviar mensaje</button>
        </form>
    )
}

export default Formulario;