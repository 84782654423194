/* conexiones locales */
/* const conCategorias = 'http://localhost/test/mantenedor/servicios/listaCategorias.php';
const conProyectos = 'http://localhost/test/mantenedor/servicios/listaProyectos.php'; 
const conCursos = 'http://localhost/test/mantenedor/servicios/listaCursos.php';
const conExperiencia = 'http://localhost/test/mantenedor/servicios/listaExperiencias.php';
const conMiniaturas = 'http://localhost/test/images/';
const conProyectosImg = 'http://localhost/test/images/'; */

/* conexiones produccion */
const conCategorias = 'https://www.davmund.cl/mantenedor/servicios/listaCategorias.php';
const conProyectos = 'https://www.davmund.cl/mantenedor/servicios/listaProyectos.php';
const conCursos = 'https://davmund.cl/mantenedor/servicios/listaCursos.php';
const conExperiencia = 'https://davmund.cl/mantenedor/servicios/listaExperiencias.php';
const conMiniaturas = 'https://davmund.cl/images/';
const conProyectosImg = 'https://davmund.cl/images/';

/* cv download*/
const cvDownload = 'img/Cv_David_Munoz_es.pdf';

export {
    conCategorias,
    conProyectos,
    conCursos,
    conExperiencia,
    conMiniaturas,
    conProyectosImg,
    cvDownload
};