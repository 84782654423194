import React, { useState } from 'react';
import './menu.css';
import { Link } from 'react-router-dom';
import { cvDownload } from '../../conexiones/varConexiones';

const Menu = (props) => {
    const [position,setPosition] = useState('home')
    const scrollToworks = () => {
        setTimeout(()=>{
            const el = document.querySelector(".main").getBoundingClientRect().top
            const scr = window.scrollY;
            const targ = scr + el - 90;
            setPosition('home')
            return window.scroll({top: targ ,behavior: "smooth"});
        },900)
      }
    return(
        <div id="menu_options" className="header_menu">
            <ul id="menu_options" className="header_menu_options">
                <Link className='Link' to="/Home"><li key="Home" data-bot="option" data-catid="0" id="_Home" onClick={()=>scrollToworks()}>Trabajos</li></Link>
                <Link className='Link' to="/About"><li key="about_me" data-bot="option" data-catid="1" id="_Sobre mi" onClick={()=>setPosition('about')}>Sobre mi</li></Link>
                <Link className='Link' to="/Contacto"><li key="contacto" data-bot="option" data-catid="2" id="_Contacto" onClick={()=>setPosition('contact')}>Contacto</li></Link>
                <a className='bot_dw_cv'  href={cvDownload} target="_blank">Descargar CV</a>
            </ul>
        </div>
    )
}

export default (Menu);