import './about.css';
import { conCursos,conExperiencia } from '../../conexiones/varConexiones.js';
import React, { Suspense, useEffect } from 'react';
import {fetchData} from '../../fetch/fetchData.js';
import Pod from '../../componentes/pod/Pod';

const apiData = fetchData(conCursos);
const apiData2 = fetchData(conExperiencia);

const About = () => {
  const data = apiData.read();
  const data2 = apiData2.read();
  function scrollingWindows (){
    return window.scrollY > 0 ? window.scroll({top: 0,behavior: "smooth"}):null;
  }
  useEffect(()=>{
    scrollingWindows()
  })
  return (
    <Suspense fallback={<>Cargando...</>}>
    <div className="bloque_aboutme">
    <div className="bloque_aboutme_img" style={{backgroundImage: `url('./assets/about/moto_tratada.jpg')`}}></div>
</div>

<div className="body_aboutme">
    <div className="section_quienSoy">
        <div className='titulo_quienSoy'>
            <h2>¿Quién soy?</h2>
        </div>
        <section>
            <div className="section_quienSoy_column">
                <div style={{backgroundImage: `url('../assets/galeria/g_3.jpg')`}}>
                    <h2 className="section_quienSoy_head">Como profesional</h2>
                    <p className="section_quienSoy_text">
                    Diseñador Gráfico Publicitario de la Universidad Santo Tomás, con más de 14 años de experiencia en diseño digital, integración y diseño interfaz de usuario, principalmente en rubros como comercio electrónico y Ti.
                    </p><p>Como profesional, me destaco por ser metódico e independiente, como también, por mi capacidad para trabajar en equipo y por estar siempre orientado a resultados. Me motiva tomar nuevos desafíos y tratar de implementar soluciones que ayuden a los usuarios finales como a los objetivos de las empresas.
                    </p>
                </div>
            </div>
            <div className="section_quienSoy_column" >
                <div>
                    <h2 className="section_quienSoy_head">Como persona</h2>
                    <p className="section_quienSoy_text">
                        Amigable y de buen trato, siempre abierto a escuchar y discutir, en buena, diferentes puntos de vista.
                    </p>
                    <p className="section_quienSoy_text">
                        Dentro de mis intereses y hobbies están la fotografía, la ilustración, el deporte y las motos. Me encanta salir a dar vueltas en moto,
                         cambiar los paisajes por un rato y comer lo que toque en el viaje.
                    </p>
                </div>
            </div>
        </section>

    </div>

    <div className="section_aboutme_experiencia">
        <div className="section_aboutme_block">
            <h2>Educación, diplomado y cursos</h2>
            <p className="experiencia_bajada">Algunos cursos y diplomados que he tomado duarante mi carrera para profundizar y ampliar conocimientos.</p>
            <div className="lineatiempo" id="educacion" data-targ="educacion">
              {data?.listacursos.map(//mapeando la lista de cursos
                            (ed)=>{
                                return <Pod key={ed.id_ed} objeto={ed} type={'ed'}></Pod>
                            }
                        )}
            </div>
        </div>
        <div className="section_aboutme_block">
            <h2>Experiencia profesional</h2>
            <p className="experiencia_bajada">En mi carrera he estado muy ligado al diseño web y digital, con algunos extraños casos en que he podido ver temas de branding o ilustración.</p>
            <div className="lineatiempo" id="experiencia" data-targ="experiencia">
            {data2?.listaexperiencias.map(//mapeando la lista de cursos
                            (exp)=>{
                                return <Pod key={exp.id_exp} objeto={exp} type={'exp'} />
                            }
                        )}
            </div>
        </div>
    </div>
    </div>
    </Suspense>
  );
}

export default About;