import './bloque.css'
import { cvDownload } from '../../conexiones/varConexiones';

const Bloque = () => {
    return(
        <>
            <div className="section_quehago">
                <div className='titulo_queHago'>
                    <h2>¿A qué me dedico?</h2>
                </div>
                <section>
                    <div className="section_quehago_column" style={{backgroundImage: `url('../assets/about/about_2.jpg')`}}>
                        <div>
                            <h2 className="section_quehago_head">Diseñador UX/UI</h2>
                            <p className="section_quehago_text">
                                Tengo un enfoque en la solución de problemas, la entrega de valor y la provisión de soluciones eficientes y eficaces para los usuarios.
                                Para esto implemento design thinkin, benchmarks (en todos sus tipos), entrevistas a usuarios, test con usuarios (tanto moderados como no moderados)
                                y heurísticas.
                            </p>
                            <span className="section_quehago_skills">
                                <h5>Skills / Herramientas: </h5>
                                <img alt="classNamiconeicon" src="./assets/icons/figma.png" />
                                <img alt="icon_sketch" src="./assets/icons/sketch.png" />
                                <img alt="icon_maze" src="./assets/icons/maze.png" />
                                <img alt="icon_hotjar" src="./assets/icons/hotjar.png" />
                                <img alt="icon_miro" src="./assets/icons/miro.png" />
                                <img alt="icon_jira" src="./assets/icons/jira.png" />
                                <img alt="icon_scrum" src="./assets/icons/scrum.png" />
                            </span>
                        </div>
                    </div>
                    <div className="section_quehago_column"  style={{backgroundImage: `url('../assets/about/about_1.jpg')`}}>
                        <div>
                            <h2 className="section_quehago_head">Diseñador Gráfico</h2>
                            <p className="section_quehago_text">
                                He trabajado en diseño de marca, impresos, digital y 
                                gráfica para máqinas de vending. Como diseñador, me preocupo de involucrar a mis clientes, 
                                tratando de familiarizarme con sus necesidades y objetivos.
                            </p>
                            <span className="section_quehago_skills">
                                <h5>Skills / Herramientas: </h5>
                                <img alt="icon_photosho" src="./assets/icons/phoshop.svg" />
                                <img alt="icon_illustrator" src="./assets/icons/illustrator.svg" />
                                <img alt="icon_indesign" src="./assets/icons/indesign.svg" />
                                <img alt="icon_after" src="./assets/icons/after.svg" />
                                <img alt="icon_premier" src="./assets/icons/premier.svg" />
                            </span>
                        </div>
                    </div>
                    <div className="section_quehago_column"  style={{backgroundImage: `url('../assets/about/about_3.jpg')`}}>
                        <div>
                            <h2 className="section_quehago_head">Front</h2>
                            <p className="section_quehago_text">
                                Desde mis inicios he estado ligado a la web, y me desenvuelvo muy bien en HTML, 
                                CSS, Javascript y Jquery. Además, he hecho cursos para profundizar en desarrollo 
                                aprendiendo sobre React js, mysql y Java.
                            </p>
                            <span className="section_quehago_skills">
                                <h5>Skills / Herramientas: </h5>
                                <img alt="icon_css" src="./assets/icons/css.svg" />
                                <img alt="icon_html" src="./assets/icons/html.svg" />
                                <img alt="icon_javascript" src="./assets/icons/javascript.svg" />
                                <img alt="icon_jquery" src="./assets/icons/jquery.svg" />
                                <img alt="icon_react" src="./assets/icons/react.svg" />
                            </span>
                        </div>
                    </div>
                </section>
                <a className='boton_cv' href={cvDownload} target="_blank"> Descargar Cv </a>{/* boton descarga */}

            </div>
        </>
    )
}


export default Bloque;