import './home.css';
import { conCategorias } from '../../conexiones/varConexiones.js';
import React, {useEffect, useState } from 'react';
import { Suspense } from 'react';
import {fetchData} from '../../fetch/fetchData.js';
import Bloque from '../../componentes/bloque_queHago/Bloque.js';
import Slide from '../../componentes/slide/Slide';
import Seccion from '../../componentes/seccion/Seccion';
import Modal from '../../componentes/modal/Modal';

const apiData = fetchData(conCategorias);

const Home = (props) => {
  const data = apiData.read();
  const [estadoModal,cambiaEstadoModal] = useState({type:null,on:false,id:null,scroll:true});
  function scrollingWindows (){
    if(estadoModal.scroll === true){
      return window.scrollY > 0 ? window.scroll({top: 0,behavior: "smooth"}):null;
    }
  }
  useEffect(()=>{
    scrollingWindows()
  })
  return (
    <div id="mainT">
      <Modal estado={estadoModal} cambiarEstado={cambiaEstadoModal}></Modal>
      <><Slide /></>
      <>
        <Suspense fallback={<div>Cargando...</div>}>
          <div className='central'>
          <Bloque />
            {data?.listacategorias.map(
              (cat)=>(
                  <Seccion key={cat.id_cat} categoria={cat.nombre_categoria} catId={cat.id_cat} action={cambiaEstadoModal}></Seccion>
              ))
            }
          </div>
        </Suspense>
      </>
      </div>
  );
}

export default Home;
